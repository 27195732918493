import { render, staticRenderFns } from "./NextTestStudent.vue?vue&type=template&id=5d3d0c58&scoped=true"
import script from "./NextTestStudent.vue?vue&type=script&lang=js"
export * from "./NextTestStudent.vue?vue&type=script&lang=js"
import style0 from "./NextTestStudent.vue?vue&type=style&index=0&id=5d3d0c58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d3d0c58",
  null
  
)

export default component.exports